import React from "react"
//import { Link } from "gatsby"
//import { HiArrowNarrowLeft} from "react-icons/hi";

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackLink from "../components/backlink"
import mnr from "../images/client-logos/metro-north-railroad.png"
import ai from "../images/client-logos/ai.png"
import homefront from "../images/client-logos/homefront.png"
import cesp from "../images/client-logos/cesp_1.png"
import ggit from "../images/client-logos/ggit.png"
import dagostino from "../images/client-logos/dagostino.png"

import "../glider.css"
import GliderComponent from "react-glider-carousel"

const About = () => (
  <Layout>
    <SEO title="About" />
    <BackLink />
    <h1>About Us</h1>

    <p className="lead">
      Red Design Lab partners with businesses and nonprofits to help them create
      and implement the design solutions and business strategies necessary to
      fully connect with their audiences and stand out and thrive in today’s
      digital landscape.
    </p>

    <p>
      Founded in early 2020, Red Design Lab is uniquely positioned to provide
      clients with a wide range of services, including: responsive web design,
      full-stack Drupal development, search engine optimization (SEO), digital
      advertising, and music creation and licencing.
    </p>

    <h2>Clients &amp; Collaborations</h2>

    <ul>
      <li>MTA</li>
      <li>Dan D’Agostino</li>
      <li>Geek Girls IT</li>
      <li>Advancing Insights</li>
      <li>NJ School Development Council</li>
      <li>Rutgers University</li>
      <li>Secure Insight</li>
      <li>HomeFront Studio</li>
      <li>The Music Den</li>
    </ul>
    <div className="about-glider">
      <GliderComponent
        hasArrows={true}
        //hasDots={true}

        // Glider options
        settings={{
          slidesToShow: 3,
          itemWidth: "300px",
          draggable: true,
          rewind: true,
          duration: 2,
        }}
      >
        <div>
          <img src={mnr} alt="metro north railroad" />
        </div>
        <div>
          <img src={ai} alt="Advancing Insights" />
        </div>
        <div>
          <img src={cesp} alt="Rutgers CESP" />
        </div>
        <div>
          <img src={homefront} alt="HomeFront Studios" />
        </div>
        <div>
          <img src={ggit} alt="Geek Girls IT" />
        </div>
        <div>
          <img src={dagostino} alt="Dan D'Agostino" />
        </div>
      </GliderComponent>
    </div>

    <BackLink />
  </Layout>
)

export default About
