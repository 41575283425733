import { Link } from "gatsby"
import React from "react"
import { HiArrowNarrowLeft} from "react-icons/hi";

const BackLink = () => (
    <div style ={{marginTop:30, marginBottom:30}}>
      <HiArrowNarrowLeft className="back-arrow" /> <Link to="/">  Go to the homepage</Link>
    </div>

)
export default BackLink